import React from 'react'
import { useState } from 'react';
import { List_sotr, List_grs, List_nmag, Menu_nmag } from './comps.js';
import { lhd_grss, printDiv } from './funcs.js';
import Lhd_sotr from './Lhd_sotr.js';
import Lhd_nmag from './Lhd_nmag.js';
import { Context } from './Context.js';
import './css/lhds_t.css';
let sfio = " "; let sdolg = " "; let sgod = "2024"; let smec = "0"; let snmag = " "; let stab = " "
function Lhds_t(props) {

  const [slhd, Setslhd] = useState({ "god": "2024", "mec": "0", "fio": " ", "dolg": " ", "nmag": " ", "tab": " " })
  const [nsort, Setnsort] = useState(1)
  const [sel, Setsel] = useState({ "sotr": "0", "god": "0", "mec": "0", "nmag": " " })
  const [s_grs, Sets_grs] = useState(0)

  let m_ar = []; m_ar.length = 0
  let w_ar = []; w_ar.length = 0
  let w_lhds = props.lhds.filter(function (ar) {
    if (slhd.dolg > ' ' && ar.dolg.indexOf(slhd.dolg) == -1)
      return false
    if (slhd.fio > " " && ar.fio.indexOf(slhd.fio) == -1)
      return false
    if (slhd.nmag !== "_Все" && slhd.nmag > " " && ar.nmag !== slhd.nmag)
      return false
    if (slhd.god > 0 && slhd.god !== ar.god)
      return false
    if (slhd.mec > 0 && slhd.mec !== ar.mec)
      return false
    if (slhd.tab > 0 && slhd.tab !== ar.sotr)
      return false
    if (props.sotr > 0 && props.sotr !== ar.sotr)
      return false
    if (s_grs > 0 && lhd_grss(ar, props.lscs).indexOf(s_grs) == -1)
      return false

    if (!(m_ar.includes(ar.nmag)))
      m_ar.push(ar.nmag)

    if (slhd.mec == 0)
      if (!(w_ar.includes(ar.sotr))) {
        w_ar.push(ar.sotr)
        return true
      }
      else
        return false
    return true
  })

  m_ar.forEach(nmag => {
    w_lhds.push({ "dolg": "Подразд.", "sotr": "", "nmag": nmag, "god": slhd.god, "mec": slhd.mec })
  })
  sort_w_lhds()
  let w_date =[]
  let w_god=slhd.god-2000
 // alert(w_god)
  let srows = w_lhds.map((ar) => {
    let w_class = "tr_lhds"
    if (ar.sotr > 0 && (ar.d_otp || ar.s_otp))
      w_class = w_class + ' ' + "tr_otp"
    if ("dprim" in ar) {
      w_date = ar.dprim.split("/")
      if ((w_date[0] - w_god)!==0)
        ar.dprim = ""
    }
    if ("duvol" in ar) {
      let w_date = ar.duvol.split("/")
      if ((w_date[0] -w_god)!==0)
        ar.duvol = ""
    }
    return (
      <tr id={ar.sotr + '@' + ar.god + '@' + ar.mec + '@' + ar.nmag} className={w_class} onClick={init_lhd}>
        <td className="tab">{ar.sotr}  </td>
        <td className="fio">{ar.fio}</td>
        <td className="dolg">{ar.dolg}  </td>
        <td className="nmag">{ar.nmag}  </td>
        <td className="date">{ar.dprim}  </td>
        <td className="date">{ar.duvol}  </td>
      </tr>
    )
  })

  let w_lhd_f = ""

  if (sel.sotr)
    w_lhd_f = <Lhd_sotr sel={sel} lhds={props.lhds} lscs={props.lscs} />
  else
    w_lhd_f = <Lhd_nmag sel={sel} lhds={props.lhds} lscs={props.lscs} />

  //<List_nmag lhds={w_lhds} Set_nmag={Set_nmag} />

  return (
    <div>
      <div id="z_head" className="z_head">
        <div style={{ "display": "flex" }}>
          <button onclick={prt_div} > Печать </button>
          <span>  &emsp;       Табель &emsp; Год <input id="god" value={sgod} onChange={e => set_state(e)} />
            &emsp; Мес <input id="mec" value={smec} onChange={e => set_state(e)} />
          </span>
          &emsp; <List_sotr lhds={w_lhds} Sets_sotr={Set_tab} />
          &emsp; <List_grs lhds={w_lhds} Sets_grs={Sets_grs} />
        </div>
        <Menu_nmag slhd={slhd} lhds={w_lhds} Set_nmag={Set_nmag} />
      </div>
      <div id="z_data" style={{ "display": "flex" }}>
        <div id="div_lhds_t" >
          <table id="table_lhds_t" >
            <thead>
              <tr >
                <th className="tab" onClick={() => Setnsort(1)}>
                  Таб №  <input id="tab" value={stab} onChange={e => set_state(e)} /> </th>
                <th onClick={() => Setnsort(2)}>
                  Фамилия И.О  &emsp;
                  <input id="fio" value={sfio} onChange={e => set_state(e)} />  &emsp;
                </th>
                <th onClick={() => Setnsort(3)} >
                  Должн <input id="dolg" value={sdolg} onChange={e => set_state(e)} />  &emsp;
                </th>
                <th onClick={() => Setnsort(4)} >
                  Подр.
                </th>
                <th onClick={() => Setnsort(5)} >
                  Принят
                </th>
                <th onClick={() => Setnsort(6)} >
                  Уволен
                </th>
              </tr>
            </thead>
            <tbody >
              {srows}
            </tbody>
          </table>
        </div >

        <div className="lhd_f" id="div_lhd_f_god" >
          {w_lhd_f}
        </div>
      </div>
    </div>
  )
  function prt_div() {
    //alert("div_lhds_t")
    printDiv("div_lhds_t")
  }
  function sort_w_lhds() {
    if (nsort) {
      w_lhds.sort(function (a, b) {
        if (nsort === 1)
          if ((a.sotr - b.sotr) > 0) return 1; else return -1;
        else
          if (nsort === 2)
            if (a.fio > b.fio) return 1; else return -1;
          else
            if (nsort === 3)
              if (a.dolg > b.dolg) return 1; else return -1;
            else
              if (nsort === 4)
                if (a.nmag > b.nmag) return 1; else return -1;
              else
                if (nsort === 5 || nsort === 6)
                  if ((a.god - b.god) > 0 || ((a.god === b.god) && (a.mec - b.mec) > 0))
                    return 1
                  else
                    return -1


      });
    }
  }
  function Set_nmag(v) {
    snmag = v
    Setslhd({ "fio": sfio, "god": sgod, "mec": smec, "dolg": sdolg, "nmag": snmag, "tab": stab })
  }
  function Set_tab(v) {
    stab = v
    Setslhd({ "fio": sfio, "god": sgod, "mec": smec, "dolg": sdolg, "nmag": snmag, "tab": stab })
  }
  function set_state(e) {
    let pid = e.currentTarget.id
    let pvalue = e.currentTarget.value
    if (pid == "fio")
      sfio = pvalue
    else
      if (pid == "nmag")
        snmag = pvalue
      else
        if (pid == "dolg")
          sdolg = pvalue
        else
          if (pid == "god")
            sgod = pvalue
          else
            if (pid == "mec")
              smec = pvalue
            else
              if (pid == "tab")
                stab = pvalue

    Setslhd({ "fio": sfio, "god": sgod, "mec": smec, "dolg": sdolg, "nmag": snmag, "tab": stab })
  }
  function init_lhd(e) {
    const pid = e.currentTarget.id
    const psotr = pid.split("@")[0]   //sotr
    const pgod = pid.split("@")[1]    //god
    const pmec = pid.split("@")[2]    //mec
    const pnmag = pid.split("@")[3]    //mag

    // if (psotr && psotr == slhd.sotr)
    //   return
    // if (pnmag && pnmag == slhd.nmag)
    //   return

    for (let i = 0; i < w_lhds.length; i++) {
      if (psotr > 0 && w_lhds[i].sotr !== psotr)
        continue
      if (pnmag !== ' ' && w_lhds[i].nmag !== pnmag)
        continue
      if (pgod > 0 && w_lhds[i].god !== pgod)
        continue
      if (pmec > 0 && w_lhds[i].mec !== pmec)
        continue
      let trs = document.getElementsByClassName("tr_lhds")
      for (const n of trs) {
        n.classList.remove("tr_lhds_active");
      }
      let tr = document.getElementById(pid)
      tr.classList.add("tr_lhds_active")
      Setsel(w_lhds[i])
      break
    }
  }

}
export default Lhds_t;