export function lhd_grss(plhd,plscs) {
    let w_ar=[]
    w_ar.length=0
    plscs.forEach((item) => {
        if (w_ar.indexOf(item.grs)>=0)
            return
        if (item.sotr == plhd.sotr && item.god == plhd.god && item.mec == plhd.mec)
            w_ar.push(item.grs)
    })
 
    return w_ar
}
export function sotr_fio(props) {
    for (const sotr of props.sotrs) {
        if (sotr['kod'] === props.psotr) {

            return sotr['fio']
        }
    }
    return (' ')

}
export function sotr_nprof(props) {
    for (const prof of props.profs) {
        if (prof['kod'] === props.pprof) {

            return props.prof['name']
        }
    }
    return (' ')

}
export function mec_name(pmec) {
    if (pmec==1) return ("Январь")
        else
    if (pmec==2) return ("Февраль")
        else
    if (pmec==3) return ("Март")
        else
    if (pmec==4) return ("Апрель")
        else
    if (pmec==5) return ("Май")
        else
    if (pmec==6) return ("Июнь")
        else
    if (pmec==7) return ("Июль")
        else
    if (pmec==8) return ("Август")
        else
    if (pmec==9) return ("Сентябрь")
        else
    if (pmec==10) return ("Октябрь")
        else
    if (pmec==11) return ("Ноябрь")
        else
    if (pmec==12) return ("Декабрь")

}
export function printDiv(pid) {
     
    var divContent = document.getElementById(pid).innerHTML;
    
    var printWin = window.open('', '_blank', 'width=800,height=600');
    printWin.document.write(`<html><head><title>Печать содержимого</title></head><body>${divContent}</body></html>`);
    printWin.document.close();
    printWin.addEventListener('load', function () {
      printWin.print();
      printWin.close();
    }, true);
  }
 