import lscs from './data/lsc.json';
import lhds from './data/lhd.json';
import React from 'react'

export function Menu_nmag(props) {
    let q_nmag = []; q_nmag.length = 0
    q_nmag.push("_Все")
    lhds.forEach(lhd => {
        if (props.slhd.god!==lhd.god)
            return
        if (props.slhd.mec>0 && props.slhd.mec!==lhd.mec)
            return
        if (!("nmag" in lhd) || lhd.nmag == " ")
            lhd.nmag = "11111"

        if (q_nmag.indexOf(lhd.nmag) == -1)
            q_nmag.push(lhd.nmag)
    })
    q_nmag.sort(function (a, b) {
        if (a > b) return 1; else return -1;
    })

    let x_nmag = q_nmag.map((ar) => {
        return (
            <button className="nmag_menu" onClick={sel_nmag} key={ar} value={ar}>{ar}</button>
        )
    })

    return (
        <div  >
                {x_nmag}
        </div>
    )
    function sel_nmag(e) {
        let nmags=document.getElementsByClassName("nmag_menu")
        for (const n of nmags) {
            n.classList.remove("nmag_active");
          }
        e.target.classList.add("nmag_active")
        props.Set_nmag(e.target.value)
    }
}



export function List_grs(props) {
    let w_ar = []; w_ar.length = 0
    let q_grs = []; q_grs.length = 0
    props.lhds.forEach(lhd => {
        lscs.forEach(lsc => {
            if (lsc.sotr !==lhd.sotr)
                return
            if (lsc.god !==lhd.god)
                return
            if (lhd.mec>0 && lsc.mec !==lhd.mec)
                return
            if (w_ar.indexOf(lsc.grs) == -1) {
                w_ar.push(lsc.grs)
                q_grs.push(lsc)
            }
        })
    })
    q_grs.sort(function (a, b) {
        if (a.sq > b.sq) return 1; else return -1;
    })
    let s_grss = q_grs.map(lsc => {
        return <option key={lsc.grs} value={lsc.grs}>{lsc.sq + ' ' + lsc.ngrs}</option>
    })

    return (
        <div >
            <select id="list_grs" onChange={(e) => { props.Sets_grs(e.target.value) }}>

                <option key="0" value="0" >Операции</option>
                {s_grss}
            </select>
        </div>
    )

}
export function List_nmag(props) {
    let q_nmag = []; q_nmag.length = 0
    props.lhds.forEach(lhd => {
        if (!("nmag" in lhd) || lhd.nmag == " ")
            lhd.nmag = "11111"

        if (q_nmag.indexOf(lhd.nmag) == -1)
            q_nmag.push(lhd.nmag)
    })


    let x_nmag = q_nmag.map((ar) => {
        return (
            <option key={ar} value={ar}>{ar}</option>
        )
    })

    return (
        <div  >
            <select id="list_nmag" onChange={(e) => props.Set_nmag(e.target.value)}>
                <option key={" "} value={" "}>Отделы </option>
                {x_nmag}
            </select>
        </div>
    )

}
export function List_sotr(props) {
    let w_ar = []; w_ar.length = 0
    let q_sotr = []; q_sotr.length = 0
    props.lhds.forEach(lhd => {
        if (w_ar.indexOf(lhd.sotr) == -1) {
            w_ar.push(lhd.sotr)
            q_sotr.push(lhd)
        }
    })
    q_sotr.sort(function (a, b) {
        if (a.fio > b.fio) return 1; else return -1;
    })
    let x_sotrs = q_sotr.map((ar) => {
        return (
            <option key={ar.sotr} value={ar.sotr}>{ar.fio}</option>
        )
    })

    return (
        <div  >
            <select id="list_sotr" onChange={(e) => props.Sets_sotr(e.target.value)}>
                <option key={"0"} value={"0"}>Сотрудники</option>
                {x_sotrs}
            </select>
        </div>
    )

}

